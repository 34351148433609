
/*@font-face{
    font-family: 'Oxygen';
    src: local('Oxygen'), url('./resources/fonts/Oxygen-Regular.ttf') format('truetype');    
} 

@font-face{
    font-family:'Oxygen';
    font-weight: bold;
    src: local('Oxygen'), url('./resources/fonts/Oxygen-Bold.ttf') format('truetype');
}

@font-face{
    font-family: 'Oxygen';
    font-weight: 100 200;
    src: local('Oxygen'), url('./resources/fonts/Oxygen-Light.ttf') format('truetype');
}
*/

@font-face{
    font-family:'MyriadPro';
    font-weight: normal;
    src: local('MyriadPro'), url('./resources/fonts/MyriadPro-Light.woff') format('woff'); 
}


@font-face{
    font-family:'MyriadPro';
    font-weight: normal;
    src: local('MyriadPro'), url('./resources/fonts/MYRIADPRO-REGULAR.woff') format('woff'); 
} 

@font-face{
    font-family:'MyriadPro';
    font-weight: bold;
    src: local('MyriadPro'), url('./resources/fonts/MYRIADPRO-SEMIBOLD.woff') format('woff'); 
}


@font-face{
    font-family:'MyriadPro';
    font-weight: 800;
    src: local('MyriadPro'), url('./resources/fonts/MYRIADPRO-BOLD.woff') format('woff'); 
}


body {
  margin: 0;
  font-family:  'MyriadPro',  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.button {
    display: inline-block;
    font-weight: 600;
    padding: 6px 14px;
    text-decoration: none;
    color: #003399;
    background-color: #fff;
    border: 1px solid #003399;
    line-height: 17px;
    cursor: pointer;
    border-radius: 2px;
}

a{cursor: pointer}

/* 1px table */ 
table.onepxtable{border-spacing: 0;}
.onepxtable  tr:first-child > td{}
.onepxtable > tbody > tr > td, .onepxtable th{border-bottom:1px solid #aaa;  border-right:1px solid #aaa; padding:5px;}
.onepxtable > tbody > tr:first-child > td, .onepxtable  tr:first-child > th{border-top:1px solid #aaa;}
.onepxtable > tbody > tr > td:first-child , .onepxtable  tr > th:first-child {border-left:1px solid #aaa;}

.mb10{margin-bottom: 10px} 

.buttonWhite{padding: 8px 10px;
    display: inline-block;
    color:white;
    margin-right: 5px;
    border-radius: 3px;
    border: 1px solid #fff;
    background: transparent;}
.buttonWhite:hover{
    color:black;
    background: white;
}
.mt30{margin-top: 30px;}
.flr{float:right;}
.clearAfter:after{content:""; clear: both;}
.tar{text-align: right}
.color333{color: #333;}
.color555{color: #555;}
.colorAAA{color: #aaa;}

.cont_w{margin: 0 auto; width:96%; max-width: 1100px; }
.cont{ margin: 50px auto; width:100%;  width:1100px;}
.cont:after{content:''; clear:both; display:block}

.mtb20 {
    margin: 20px 0;
}

.mt20{margin-top: 20px;}
.mt40{margin-top: 40px;}


a, button{outline:none;}
