body{ 
    background: rgb(0,0,0) url('../../resources/images/tlo.jpg') no-repeat left top; background-size: 130%; color:#fff; font-family: MyriadPro, Arial, sans-serif;}
p{font-size: 16px; text-align: justify; line-height: 1.5em; color:rgba(255,255,255,0.8)}

#App{}
#ContentPage{ min-height: 650px;}


.heading_separator::after {
    content: "";
    display: block;
    width: 50px;
    height: 7px;
    background-color: rgb(239,64,87);
    margin: 0 auto;
    position: relative;
    top: 24px;
}

.heading_separator {
    clear: both;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 25px;
    font-weight: bold;
    margin-top: 70px;
    margin-bottom: 40px;
    padding-bottom: 20px;
    color: #333;
    text-align: center;
    border-bottom: 1px solid #ccc;
}




@media all and (max-width:800px){
    body{background-size: 200%;}
    p{font-size: 13px;  line-height: 1.4em;}
    #ContentPage{ padding: 0 10px; box-sizing: border-box}
    
    #PodcastList .MuiAccordionSummary-root{
        padding: 0 4px;
    }
}