header{display: flex;}
.mtb10{margin-top: 10px; margin-bottom: 10px}
    header label{display: block}
    #HeaderContent{margin-top:70px; margin-bottom: 40px; width: 340px;}
           
        h1#HeaderTitle{font-size: 40px;
            font-weight: 600;
            letter-spacing: 2px;
            margin-top: 10px; margin-bottom: 10px;}
        #HeaderText{font-size: 12px; margin-bottom: 20px;}
        #HeaderLogoLink{margin-top: 10px}
            #HeaderLogo{ height: 50px; width: auto;}

    #MainNavOuter{flex-grow:1;}
        #MainNavOuter label{font-size: 14px;}
        #MainNav{}
            #MainNav  ul{list-style: none; display: flex; justify-content: flex-end;}
                #MainNav  ul li{ margin: 10px 10px 10px 20px;  }
                    #MainNav  ul li a{color:#fff; text-decoration: none; padding-left: 28px;}
                    
                    #HomeLink{background:url('../../resources/images/home.svg') no-repeat left center; background-size:20px; }
                    #RadioLink{background:url('../../resources/images/globe.svg') no-repeat left center;  background-size:20px;}
                    #ArchiwumLink{background:url('../../resources/images/archive.svg') no-repeat left center;  background-size:20px;}
    
#SearchOuter{display: flex; justify-content: flex-end;  position: relative; clear: right}
    #SearchText{ opacity: 0.7; padding:2px 6px;  box-sizing: border-box; height: 40px; width: 300px; border: 1px solid #fff;}
    #SearchBt{cursor:pointer; height: 40px; width: 40px; box-sizing: border-box;  border:rgba(255,255,255, 0.9);  background: rgba(255,255,255, 0.8) url('../../resources/images/search.svg') no-repeat center center; background-size:16px;}
    #ClearBt{position: absolute; right: 40px; height: 40px; background: none; border: none; color: #fff; cursor: pointer;}
    
    #SearchWarning{position: absolute; bottom:-16px; font-size: 9px; color:rgba(220, 220, 220, 0.3); right:0}
    
.searchDetails{width:300px; float: right; clear: right}
    .searchDetails input[type='text'], .searchDetails input[type='date'],  .searchDetails select{background: white; opacity: 0.7; padding:2px 6px;  box-sizing: border-box; height: 40px; width: 100%; border: 1px solid #fff; font-size: 14px; }
    
        .daty_cont{display: flex; width: 100%; justify-content: space-between; margin-top: 2px; align-items: center; }
            .daty_cont > *{margin-right: 4px; font-size: 13px;}
            .daty_cont  input{flex-grow:1}
            .searchDetails .daty_cont div:first-child input{width: 95%}
    
@media all and (max-width:800px){
    
    header{flex-wrap: wrap}
        #HeaderContent{ width: 100%; flex: 1 0 auto; margin:0; display: flex; align-items: center; justify-content: space-between}
            h1#HeaderTitle{font-size: 28px;margin: 10px ;}
            #HeaderLogoLink{margin: 10px}
            #HeaderLogo{width: 100px; height: auto;}
            #HeaderText{display: none}
        #MainNavOuter{ width: 100%; flex: 1 0 auto; padding: 10px; box-sizing: border-box;  justify-content: center; }
            #MainNav{display: none;}
            #SearchOuter{justify-content: center;  display: flex; width: 100%;  }
                #SearchText{ flex: 1 0 240px; width: 0;}
                #SearchBt{ flex: 0 0 40px; width: 0;}
                
                .daty_cont{justify-content: flex-start;}
                .daty_cont label{flex-basis: 30px; text-align: center}
            .searchDetails{width:100%;}
}