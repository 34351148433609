#CategoryPage{}

    section#catTop{ display: flex; align-items: flex-start }
        .catImageOuter{width:300px; flex-shrink: 0}
            .catImage{width:100%; height: auto; border-radius: 4px;}
            
        .descrOuter{flex-grow: 1; margin-left: 20px; text-align: justify}
        
    section#catBroadcasts{margin-top: 40px;}
    
    
   
#BreadCrumb{ display: flex; align-items: center; }
#BreadCrumb a{ color:#fff; cursor: pointer; color:#fff; text-decoration: underline}
#BreadCrumb > * {padding:10px; cursor: pointer}
    
    #BreadCrumbStart { background: url('../../resources/images/home.svg') no-repeat center center; background-size: 20px;  width: 24px; height: 24px;  }
    
    
@media all and (max-width:800px){
    
     #BreadCrumb{ justify-content: flex-start; flex-wrap: wrap; font-size: 14px;}
        #BreadCrumb a{ }
    
    section#catTop{display: block; }
        .catImageOuter{width:120px; float: left; margin-right: 10px; margin-bottom: 3px; margin-top: 5px;}
                .catImage{width:100%; height: auto; border-radius: 4px;}
                .descrOuter{margin-left: auto;}


    section#catBroadcasts{padding-top: 20px; margin-top: 0; clear: both;}
            
            
}