#PlayerCloseArrowBt{display:none}
#Player{position: fixed; transition: all 0.5s ease-in-out; padding: 5px; background-color: #000; color: #fff; bottom: 0; left: 0; right: 0; }

    #PlayerClose{display: none}

    #PlayerCloseArrowBt:active{color:red;}
    #PlayerCloseArrowBt.closed {background-color:rgb(13,121,166); border-top-right-radius: 20px; border-bottom-right-radius: 20px;}
        #PlayerCloseArrowBt.open img{width: 30px;}
        #PlayerCloseArrowBt.closed img{width: 30px; border-radius: 50%; border:2px solid #eee;}
    
    
    #Player button{cursor: pointer}
    #PlayerInner{width: 96%; max-width: 1100px; margin: 0 auto; display: flex; }
    
        #PlayerImageCont{display: none};

        #PlayerStatus{width:100px; display: flex; align-items: center}
        #PlayerTrackTitle{flex-grow:1; display: flex; align-items: center}

        #PlayerControls{ display: flex; align-items: center}
        #PlayerControls > *{ margin:0 2px; }
            #PlayerControlsButtons{ margin-right: 20px; flex-shrink: 0}
                #PlayerControlsButtons button{ transition: all 0.2s; border: none; padding: 0; background-size:25px; height: 35px; width: 35px;}
                #PlayerControlsButtons button:hover{ border: none; padding: 0; }
                #Prev{ background:url('../../resources/images/prev.svg') no-repeat center center;  }
                #Next{ background:url('../../resources/images/next.svg') no-repeat center center; }
                #PlayerControlsButtons  #Play{background:url('../../resources/images/play.svg') no-repeat center center; background-size:25px;  border:1px solid #ccc; border-radius: 50%;  }
                #PlayerControlsButtons #Pause{background:url('../../resources/images/pause.svg') no-repeat center center; background-size:35px;  border:1px solid #ccc; border-radius: 50%; }

                #Mute{background:url('../../resources/images/vol.svg') no-repeat center center;  border: none;  padding: 0; background-size:14px; height: 35px; width: 30px;}
                #Mute.muted{background:url('../../resources/images/mute.svg') no-repeat center center; background-size:14px; }
            #PlayerTimeOuter{display: flex; align-items: center;}
                #PlayerControlsTrack{width:200px; margin:0 8px; display: flex; height: 2px; background: #888; align-items: center; position: relative}
                #PlayerProgress{background-color: #ef4057; height: 100%; }
                #PlayerProgressSeek{width:100%; height: 20px; position: absolute; cursor: pointer }
            .playerTime{font-size: 12px; font-weight: bold; width: 40px; text-align: center;}


        #PlayerSoundOuter{display: flex; align-items: center}        
            #PlayerControlsSound{width:60px; opacity: 0; transition:all 0.3s; }

            #PlayerSoundOuter:hover #PlayerControlsSound{opacity: 1;}
                input[type='range']#PlayerVolume{background-color: transparent; width:100%;}
                input[type='range']#PlayerVolume::-moz-range-thumb{cursor:pointer; background:#888; width: 10px; height: 10px; border-radius: 50%}
                input[type='range']#PlayerVolume::-moz-range-track{background:#eee;}
                input[type='range']#PlayerVolume::-moz-range-progress{background:#ef4057; height: 2px;}


@media all and (max-width:800px){
    
    #PlayerCloseArrowBt{ position: fixed; z-index: 10; display: flex; align-items: center; justify-content: flex-end; padding-right: 2px; left: 0; bottom:40px; width: 60px; height: 40px; line-height: 40px; font-size: 30px; font-weight: bold; cursor: pointer}
    #Player{ height: 100vh; width: 100vw;  }
    #Player.closed{ transform: translateX(-100%);}
    #Player.open{transform: none;}
    #PlayerClose{display: inline-block; width: 30px; height: 30px; position: absolute; top: 20px; right: 20px; border:1px solid #fff; border-radius: 3px; text-align: center; line-height: 30px;}

        #PlayerInner{width:auto; height: 90vh; align-content: flex-end; justify-content: center; flex-wrap: wrap;}
            #PlayerImageCont{display: block; text-align: center;}
                #PlayerImage{display: inline-block; width: 70%; border-radius: 4px;}
            #PlayerStatus{width: 100%; text-align: center;display: flex; justify-content: center;  }
            #PlayerTrackTitle{width: 100%; text-align: center; min-height: 20vh; display: flex; justify-content: center; }
                    #PlayerTrackTitle > div{ font-size: 22px; padding: 0 20px;}
            #PlayerControls{ flex-wrap: wrap;  }
                #PlayerControlsButtons{width:100%; display: flex; align-content: center; justify-content: center; height: 15vh; margin-right: 0}
                    #PlayerControlsButtons button{height: 70px; width: 70px; }
                    #PlayerControlsButtons button:hover{ }
                    #PlayerControlsButtons #Play {  border:1px solid #ccc; border-radius: 50%; background-size:40px; }
                    #PlayerControlsButtons #Pause{ border:1px solid #ccc; border-radius: 50%; background-size:50px; }
                    #PlayerControlsButtons #Prev, #PlayerControlsButtons #Next{ background-size:40px; }
                #PlayerTimeOuter{width:90%; margin:0 auto; display: flex; align-content: center; justify-content: space-between;}
                    #PlayerTime{}
                    #PlayerControlsTrack{width:auto; min-width: 200px; flex: 1 0 200px;}
                    #PlayerTimeTotal{}

                #PlayerSoundOuter{display: flex;  width:90%; margin:0 auto;  justify-content: flex-end; align-items: flex-end; height: 50px;}
                    #Mute{background-size:20px;}
                    #Mute.muted{background-size:20px;}
                    #PlayerControlsSound{display: none;}
}


