ul#BroadcastList{list-style: none; padding: 0; margin:0; display: grid; grid-gap:10px; grid-template-columns:repeat(6, 1fr)}
    ul#BroadcastList li{ position: relative;}
        a.broadcastBox:before{content:''; border-radius: 4px; position:absolute; width: 100%; height:100%; background: rgba(11, 108, 154, 0.6);}
        a.broadcastBox:hover:before{background:rgba(0,0,0, 0.2); transition: all 0.4s;}
        a.broadcastBox{display: block; border-radius: 4px; height: 200px;  text-decoration: none;}
            .catBoxInner{position: relative; top:50%; transform:translateY(-50%)}
                h4.broadcastBoxTitle{text-transform: uppercase; font-weight: normal; font-size: 16px; margin: 0; text-align: center; color: #fff; }
                .broadcastBoxBcCount{text-align: center; color: rgb(200,200,200); font-size: 13px;}
                
@media all and (max-width:800px){
    ul#BroadcastList{ grid-template-columns:repeat(2, 1fr); }

}