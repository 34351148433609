#PodcastList{}
    #PodcastListHeader{display: flex; justify-content: space-between; align-items: center}
    
        #SortFilterCont > *, .selectPodcastPage{border:none; line-height: 20px; background: white; opacity: 0.5; padding: 6px; font-size: 14px; color: #333;}
        #PodcastNameFilter{ }
        #PodcastSort{margin-left: 5px;}
        #PodcastSort:focus, .selectPodcastPage:focus{color: #333}

    .podcastItem{ }
        .podcastItemHeader .MuiAccordionSummary-content{display: flex; align-items: center}
            .PI_play{background:url('../../resources/images/play.svg') no-repeat center center; cursor: pointer;
                     border-radius: 50%;
                     border: 2px solid white; padding: 0; background-size:30px; height: 35px; width: 35px; transition: all 0.3s; 
                     flex: 0 0 35px;
            }
            .PI_play.playing{background:url('../../resources/images/pause_red.svg') no-repeat center center; background-size:30px;  }
            .PI_title{margin-left: 10px;}
           .podcastStoryLink{margin-left: 10px;}
                .podcastAuthor{color: rgba(255,255,255, 0.7); margin-left: 1px; }
                .podcastTimespan{color: rgba(255,255,255, 0.7); }

        .podcastItemDescr{display: block !important; }
            .podcastItemDescrInner{height: 6em; overflow: hidden}
                .podcastItemDescrInner p{}
                .podcastDescrMoreBt{}

        .podcastyButtonsCont{margin-top: 10px;}
            .podcastBt{padding: 8px 10px; display: inline-block; margin-right: 5px; border-radius: 3px; border: 1px solid #fff; background: transparent;}
            .podcastBt:hover{background: white; color: black;}
            
/* story */            


     .storyModalInner{background-color: white; overflow-y: auto;  border: 20px solid #fff; border-radius: 8px; outline: 0; color: #333; position: absolute; max-height: 80%; width: 840px; min-height: 400px; top:50%; left: 50%; transform: translate(-50%, -50%); }   

     .storyModalInner b{font-weight: 800;}
     
        #story{padding: 10px 15px; background-color: #fff; }
            #story > table{border: 1px dashed #888;}
            #story .button{background-color: #fff; border:1px solid black; color:black }
            #story .button:hover{background-color: black; border:1px solid black; color:white}
            
        #story .slowa_kluczowe span{margin-right: 3px;}
        #story .slowa_kluczowe span:after{content:","; display: inline-block;}
        .closeModal{position: absolute; top:30px; right: 12px; }
        .storyButtonCont a{margin-right: 5px;}
        
            #story{}
            #story table td{height: 30px;}

            .fixed_table{table-layout: fixed}
            .printCont{min-height: 25px;}

          
        .story_table > tbody > tr > td{border-bottom: 1px dashed #888;}
        .story_table > tbody > tr:last-of-type > td{border-bottom: none;}
      
          /*
        table.story_table{border-spacing: 0;}
        .story_table  tr:first-child > td{}
        .story_table > tbody > tr > td, .story_table th{border-bottom:1px dashed #aaa;  border-right:1px dashed #aaa; padding:5px;}
        .story_table > tbody > tr:first-child > td, .story_table  tr:first-child > th{border-top:1px dashed #aaa;}
        .story_table > tbody > tr > td:first-child , .story_table  tr > th:first-child {border-left:1px dashed #aaa;}
             */
    
    .preview_table_800{width: 800px;}

        
     #PodcastsPages{margin-top: 10px; display: flex; align-items: flex-end; justify-content:space-between;}
        .podcastPage{border: 1px solid #555; cursor: pointer; background: transparent; width: 30px; height: 30px; line-height: 30px; text-align: center; border-radius: 2px; display: inline-block; margin: 5px;}
        .podcastPage:hover, .podcastPage.selected{background: white; color: black;}
        .podcastPageNotSelectable{border: 1px solid #222; color: #222; cursor: pointer; background: transparent; width: 30px; height: 30px; line-height: 30px; text-align: center; border-radius: 2px; display: inline-block; margin: 5px;}

#noRes{color: #aaa; margin-top: 20px;}
        

 ::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
        width: 4px;
    }

    ::-webkit-scrollbar:horizontal {
        height: 8px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: black;
        border-radius: 4px;
        border: none;
    }

    ::-webkit-scrollbar-track {
        border-radius: 2px;
        background-color: #ddd;
        width: 1px;
    }
        

@media all and (max-width:800px){
    
      #story{padding: 10px 10px 50px 6px; }
                .fixed_table{}
    
    #PodcastList{ }
    #SortFilterCont{text-align: right}
    #SortFilterCont > * {width: 190px; box-sizing: border-box;}
    #PodcastNameFilter{ margin-bottom: 5px; }
    .printButton{display:none;}
    .closeModal{position: absolute; top:20px; right: 17px; }
    
    .storyModalInner{ width: 86%; border: 10px solid #fff; padding-right:0; border-right: 10px solid white;}
    
    
   
}

@media print{
    html, body {
        width: 210mm;
        height: 297mm;
        
      }

      .fixed_table{table-layout: auto}
    body{background: white !important;}
        
    #root{display: none;}
    #lay_content{padding: 0 !important;}
    .hide2print{display: none}
    
    table { page-break-after:auto }
    tr    { page-break-inside: auto; page-break-after:auto }
    td    { page-break-inside:auto; page-break-after:auto }
    thead {  }
    tfoot {  }
    .preview_table_800{width: 100%;  box-sizing: border-box; }
    
    .storyModalInner{position: static; display: inline; left:0 ; top: 0; right: 10px; box-sizing: border-box; transform: none; height: auto;  border-radius: 0; overflow: visible; font-size: 13px; }

    @page {
        margin: 1cm;
        size:portrait;
     }



}


