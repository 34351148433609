.clearfix::after {
    content: "";
    clear: both;
    display: block;
}

/***************** FOOTER *****************************/ 
#footer1{background-color:rgb(0,30,54); margin-top: 50px; padding-top: 1px; padding-bottom: 0;}
    .footer{ color: #fff;}
    .footer .heading_separator{color:#fff;}
        .footer_col_inner{padding: 20px 60px; color: #fff; display: flex; justify-content: space-between}

    #footerCol1{}
    #footer_cookiesinfo{text-align: center; background-color: rgb(0,19,33); padding: 10px 0; }

    #footer_cookiesinfo a{color:blue; text-decoration: underline;}
    
/* OGÓLNE */
a{text-decoration: none}
p{margin:5px 0;}
.redbar_under{}
.redbar_under::after {
    background-color: rgb(239, 64, 87);
    content: "";
    display: block;
    height: 4px;
    margin-top: 10px;
    width: 50px;
}

    

@media all and (max-width:800px){
    .hideMobile{display: none}
    #footer1{ padding:10px; box-sizing: border-box;}
    
     .footer_col_inner{flex-direction: column; align-items: center}
      .footer_col_inner > *{margin-bottom: 20px; width: 175px; overflow: visible; white-space: nowrap}
    
    .heading_separator{margin-top:10px !important; margin-bottom: 20px !important; padding-top:20px  !important;}
    .heading_separator:first-child{margin-top:5px; padding-top: 10px;}

}